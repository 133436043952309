import { HomePageFaqs } from "@/DummyData";
import {
  FAQMain,
  FAQMainSub,
  FAQMainSubColA,
  FAQMainSubColQ,
  FAQTitle,
  TeamHeaderMain,
} from "@/StyledComponents/TeamStyled";
import { motion } from "framer-motion";
import { useState } from "react";
import { Container } from "reactstrap";
import HalfDownArrow from "../svg/HalfDownArrow";
import RedRoundline from "../svg/RedRoundline";
import WaveCurveLine from "../svg/WaveCurveLine";
import {
  PortfolioBottom,
  ShowProjectsButton,
} from "@/StyledComponents/LandingStyled";
import { Icon } from "@iconify/react";
import BottomLineSvg from "../svg/BottomLineSvg";

export default function NewFaq() {
  const [showAll, setShowAll] = useState(false);

  const formattedFaqs = HomePageFaqs.map((faq) => ({
    ...faq,
    heading: faq.question.replace(/\n/g, "<br />"),
    inner: faq.answer.replace(/\n/g, "<br />"),
  }));

  const displayedFaqs = showAll ? formattedFaqs : formattedFaqs.slice(0, 0);
  const customEase = [0, 0, 0.5, 1];
  return (
    <Container className="p-b-section">
      <TeamHeaderMain className="!py-0">
        <FAQMain className="py-0">
          <motion.div
            initial={{ opacity: 0, y: 25 }}
            transition={{
              duration: 1,
              ease: customEase,
              delay: 0.0,
            }}
            whileInView={{ opacity: 1, y: 0 }}
          >
            <FAQMainSub className="">
              <FAQMainSubColQ className="">
                <FAQTitle>
                  What is the process <br />
                  of web application
                  <br />
                  <span className="relative">
                    {" "}
                    development?
                    <span className="absolute bottom-[-10px] right-0">
                      <BottomLineSvg />
                    </span>
                  </span>
                </FAQTitle>
              </FAQMainSubColQ>
              <FAQMainSubColA>
                <p>
                  Our{" "}
                  <a
                    className="HUderLine"
                    href="/web-application-development-company"
                    target="_self"
                  >
                    web application development
                  </a>{" "}
                  process starts with understanding your business needs,
                  followed by designing the architecture, development, and
                  rigorous testing to ensure seamless functionality.
                </p>
              </FAQMainSubColA>
            </FAQMainSub>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 25 }}
            transition={{
              duration: 1,
              ease: customEase,
              delay: 0.0,
            }}
            whileInView={{ opacity: 1, y: 0 }}
          >
            <FAQMainSub>
              <FAQMainSubColQ>
                <FAQTitle>
                  How long does it <br />
                  take to develop a <br />
                  <span className="relative">
                    {" "}
                    mobile app?
                    <span className="absolute bottom-[-15px] right-0">
                      <WaveCurveLine />
                    </span>
                  </span>
                </FAQTitle>
              </FAQMainSubColQ>
              <FAQMainSubColA>
                <p>
                  The timeline for{" "}
                  <a
                    className="HUderLine"
                    href="/mobile-app-development-company"
                    target="_self"
                  >
                    mobile app development
                  </a>{" "}
                  depends on the complexity of the app, ranging from 2-6 months.
                  We provide detailed timelines after a project scope
                  discussion.
                </p>
              </FAQMainSubColA>
            </FAQMainSub>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 25 }}
            transition={{
              duration: 1,
              ease: customEase,
              delay: 0.0,
            }}
            whileInView={{ opacity: 1, y: 0 }}
          >
            <FAQMainSub className="">
              <FAQMainSubColQ className="">
                <FAQTitle>
                  What are the <br />
                  <span className="relative">
                    benefits{" "}
                    <span className="absolute bottom-[-10px] left-[-20px]">
                      <RedRoundline />
                    </span>
                  </span>
                  of custom <br />
                  software development?
                </FAQTitle>
              </FAQMainSubColQ>
              <FAQMainSubColA>
                <p>
                  Custom software development provides tailored solutions that
                  meet your business’s unique requirements, offering
                  scalability, flexibility, and enhanced security compared to
                  off-the-shelf software.
                </p>
              </FAQMainSubColA>
            </FAQMainSub>
          </motion.div>

          {showAll && (
            <>
              {displayedFaqs.map((faq) => (
                <motion.div
                  key={faq.id}
                  initial={{ opacity: 0, y: 25 }}
                  transition={{
                    duration: 1,
                    ease: customEase,
                    delay: 0.0,
                  }}
                  whileInView={{ opacity: 1, y: 0 }}
                >
                  <FAQMainSub className="">
                    <FAQMainSubColQ className="">
                      <FAQTitle
                        className=""
                        dangerouslySetInnerHTML={{ __html: faq.heading }}
                      ></FAQTitle>
                      {faq.id === "1" && (
                        <span className="absolute flex items-center justify-center w-[50px] lg:w-[140px] bottom-[30px] lg:bottom-[30px] right-[49%] lg:right-[62%] md:right-[53%] firstIcon">
                          <HalfDownArrow />
                        </span>
                      )}
                      {faq.id === "2" && (
                        <span className="absolute flex items-center justify-center screen w-[70px] lg:w-[140px] -bottom-[5px] lg:bottom-[25px] right-[64%] lg:right-[70%] secIcon">
                          <WaveCurveLine />
                        </span>
                      )}
                      {faq.id === "3" && (
                        <span className="absolute flex items-center justify-center w-[70px] lg:w-[150px] -left-[10px] top-[2px] lg:top-[40px] md:-top-[1px] md:-left-[18px] lg:-left-[1%] thrdIcon">
                          <RedRoundline />
                        </span>
                      )}
                    </FAQMainSubColQ>
                    <FAQMainSubColA className="">
                      <p
                        className=""
                        dangerouslySetInnerHTML={{ __html: faq.answer }}
                      />
                      {/* Do not Remove comment code */}
                      {/* <FrequentlyMobileList>
                    <List>
                      {faq.ul.length > 0 &&
                        faq.ul.map((j) => (
                          <li key={j.id}>
                            <Icon icon="humbleicons:arrow-right" /> {j.name}
                          </li>
                        ))}
                    </List>
                  </FrequentlyMobileList> */}
                    </FAQMainSubColA>
                  </FAQMainSub>
                </motion.div>
              ))}
            </>
          )}

          <PortfolioBottom>
            <ShowProjectsButton
              className="orange-app-btn"
              onClick={() => setShowAll(!showAll)}
            >
              {!showAll ? "View More" : "View Less"}{" "}
              {showAll ? (
                <motion.span
                  animate={{ y: [-3, 6, -3] }}
                  transition={{
                    duration: 1,
                    repeat: Infinity,
                    ease: "linear",
                  }}
                >
                  <Icon icon="solar:arrow-up-outline" />
                </motion.span>
              ) : (
                <motion.span
                  animate={{ y: [-3, 6, -3] }}
                  transition={{
                    duration: 1,
                    repeat: Infinity,
                    ease: "linear",
                  }}
                >
                  <Icon icon="solar:arrow-down-outline" />
                </motion.span>
              )}
            </ShowProjectsButton>
          </PortfolioBottom>
        </FAQMain>
      </TeamHeaderMain>
    </Container>
  );
}
